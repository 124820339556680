import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _46c32226 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _ab9d78ce = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _0384ba3f = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _1f91948b = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _837e32d4 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _125b334e = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _082e0216 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _4f1e9672 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _4b2d3caa = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _06a1fac2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1d857c30 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _a9a72d98 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _541246a8 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _251bbdb0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _7d90f100 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _41172270 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _b6eab608 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0661e944 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1acd6a83 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _2cd3849d = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _7f9693eb = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _46c32226,
    name: "home___en"
  }, {
    path: "/it",
    component: _46c32226,
    name: "home___it"
  }, {
    path: "/en/cart",
    component: _ab9d78ce,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _0384ba3f,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _1f91948b,
      name: "billing___en"
    }, {
      path: "payment",
      component: _837e32d4,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _125b334e,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _082e0216,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _4f1e9672,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Cms",
    component: _4b2d3caa,
    name: "Cms___en"
  }, {
    path: "/en/customer",
    component: _06a1fac2,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _1d857c30,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _a9a72d98,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _541246a8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _251bbdb0,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _7d90f100,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _41172270,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _b6eab608,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _0661e944,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _1acd6a83,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/Home",
    component: _46c32226,
    name: "Home___en"
  }, {
    path: "/en/Page",
    component: _2cd3849d,
    name: "Page___en"
  }, {
    path: "/en/reset-password",
    component: _7f9693eb,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/it/cart",
    component: _ab9d78ce,
    name: "cart___it"
  }, {
    path: "/it/checkout",
    component: _0384ba3f,
    name: "checkout___it",
    children: [{
      path: "billing",
      component: _1f91948b,
      name: "billing___it"
    }, {
      path: "payment",
      component: _837e32d4,
      name: "payment___it"
    }, {
      path: "shipping",
      component: _125b334e,
      name: "shipping___it"
    }, {
      path: "thank-you",
      component: _082e0216,
      name: "thank-you___it"
    }, {
      path: "user-account",
      component: _4f1e9672,
      name: "user-account___it"
    }]
  }, {
    path: "/it/Cms",
    component: _4b2d3caa,
    name: "Cms___it"
  }, {
    path: "/it/customer",
    component: _06a1fac2,
    meta: {"titleLabel":"My Account"},
    name: "customer___it",
    children: [{
      path: "addresses-details",
      component: _1d857c30,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___it"
    }, {
      path: "my-newsletter",
      component: _a9a72d98,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___it"
    }, {
      path: "my-profile",
      component: _541246a8,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___it"
    }, {
      path: "my-reviews",
      component: _251bbdb0,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___it"
    }, {
      path: "my-wishlist",
      component: _7d90f100,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___it"
    }, {
      path: "order-history",
      component: _41172270,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___it"
    }, {
      path: "addresses-details/create",
      component: _b6eab608,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___it"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _0661e944,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___it"
    }, {
      path: "order-history/:orderId",
      component: _1acd6a83,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___it"
    }]
  }, {
    path: "/it/Home",
    component: _46c32226,
    name: "Home___it"
  }, {
    path: "/it/Page",
    component: _2cd3849d,
    name: "Page___it"
  }, {
    path: "/it/reset-password",
    component: _7f9693eb,
    alias: "/customer/account/createPassword",
    name: "reset-password___it"
  }, {
    path: "/en/:slug+",
    component: _2cd3849d,
    name: "page___en"
  }, {
    path: "/it/:slug+",
    component: _2cd3849d,
    name: "page___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
